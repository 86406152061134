import api from "@/api";
import companyId from "@/api/modules/companyId";
import { broadcast } from "@/helpers/broadcast";
import { DateTime } from 'luxon';

/**
 * @param {ReturnType<(import("@/store").useStore)>} $store
 * @param {import("vue-router").Router} $router
 */
export function setupStore($store, $router) {
	// Update online and offline status
	function updateOnlineStatus() {
		$store.isOnline = navigator.onLine;
	}
	updateOnlineStatus();
	window.addEventListener('online', updateOnlineStatus);
	window.addEventListener('offline', updateOnlineStatus);

	// PWA install banner
	window.addEventListener('beforeinstallprompt', (e) => {
		e.preventDefault();
		$store.showInstallBanner = localStorage.getItem('pwaUserChoice') != 'no';
		$store.installPrompt = e;
	});

	api.auth.addCallback(async response => {
		if (response.headers.has('x-nitrogen-refreshauth')) {
			await $store.refresh();
			const to = $router.currentRoute.value;
			await $router.replace({
				path: to.path,
				query: to.query,
				hash: to.hash,
				force: true,
			});
		}
	});

	broadcast.addEventListener('message', async event => {
		if (typeof event.data === 'object' && event.data !== null) {
			if (event.data.key === 'lastCacheRefresh') {
				const d = event.data.value ? DateTime.fromISO(event.data.value) : null
				$store.lastCacheRefresh = d && d.isValid ? d : null;
			} else if (event.data.key === 'localChangesSyncing') {
				$store.localChangesSyncing = event.data.value;
			} else if (event.data.key === 'localChangesCount') {
				$store.localChangesCount = event.data.value;
			} else if (event.data.key === 'companyId') {
				if (await companyId.setFromIdb()) {
					if ($router.currentRoute.value.meta.lockOnCompanyChange) {
						$store.showCompanyChangeOverlay = $store.user.companyId !== companyId.get();
					} else {
						await $store.refresh();
					}
				}
			}
		}
	});
	if (navigator.serviceWorker) {
		navigator.serviceWorker.ready.then(worker => {
			worker.active.postMessage('rebroadcast-localChangesSyncing');
			worker.active.postMessage('rebroadcast-localChangesCount');
		});
	}
}
