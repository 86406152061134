import companyId from '@/api/modules/companyId';
import MessageType from '@/models/MessageType';
import Permission from '@/models/Permission';
import { useStore } from '@/store';
import AccessDeniedPage from '@/views/AccessDeniedPage.vue';
import LicenseAgreementPage from '@/views/LicenseAgreementPage.vue';
import NotFoundPage from '@/views/NotFoundPage.vue';
import OfflinePage from '@/views/OfflinePage.vue';
import PrivacyPolicyPage from '@/views/PrivacyPolicyPage.vue';
import { computed } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import { handleRouterError } from './_helpers';
import account from './modules/account';
import admin from './modules/admin';
import operator from './modules/operator';
import portal from './modules/portal';

function getHomePagePath() {
	const store = useStore();
	let path = '/404';
	if (!store.isLoggedIn) {
		path = '/Account/Login';
	} else {
		if (store.isUser) {
			if (store.isOperator) {
				path = '/Portal/Operator';
			} else if (store.userHasThisPermission(Permission.companyOffice)) {
				path = '/Portal';
			} else {
				path = '/Account/Manage';
			}
		} else if (store.user.isAdmin) {
			path = '/Admin';
		} else {
			path = '/Account/Companies';
		}
	}
	return path;
}

export const homePage = computed(() => router.resolve(getHomePagePath()));

const routes = [
	{
		path: '/',
		meta: { title: 'Home', },
		redirect: to => ({ path: getHomePagePath(), query: to.query }),
	},
	...account,
	admin,
	portal,
	operator,
	{
		path: '/AccessDenied',
		component: AccessDeniedPage,
		meta: { title: 'Access Denied', isErrorPage: true, },
	},
	{
		path: '/PrivacyPolicy',
		component: PrivacyPolicyPage,
		meta: { title: 'Privacy Policy', },
	},
	{
		path: '/LicenseAgreement',
		component: LicenseAgreementPage,
		meta: { title: 'License Agreement', },
	},
	{
		path: '/Offline',
		component: OfflinePage,
		meta: { title: 'Offline', isErrorPage: true, },
		props: route => ({ referrer: route.query.referrer, }),
	},
	{
		path: '/:path(.*)*',
		component: NotFoundPage,
		meta: { title: 'Page Not Found', is404: true, isErrorPage: true, },
	},
]

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: routes,
	scrollBehavior(to, from, savedPosition) {
		if ((from.path === to.path && to.params)) {
			// Don't scroll if only the parameters are changing (e.g. tabs, pagination, filters)
			return;
		} else if (from.meta.workOrderPage && to.meta.workOrderPage) {
			// If office work order page, smooth scroll to main
			return { top: 0, el: '#main', behavior: 'smooth', }
		} else {
			// Default scroll to top, no animation
			return { top: 0, behavior: 'instant', }
		}
	},
});

async function addMessageFromQuery($store, to) {
	const message = to.query.message;
	const messageType = to.query.messageType;
	if (message) {
		const query = Object.assign({}, to.query);
		delete query.message;
		delete query.messageType;
		$store.addMessage(message, messageType);
		// redirect to the same path, without the message
		return {
			path: to.path,
			query: query,
			hash: to.hash,
		};
	}
}

router.beforeEach(async (to, from) => {
	const $store = useStore();
	const messageRedirect = await addMessageFromQuery($store, to);
	if (messageRedirect) { return messageRedirect; }

	if (to.meta.blockOffline && !$store.isOnline) {
		return {
			path: '/offline',
			query: { referrer: to.fullPath },
		}
	}

	// if this route requires auth and the user is not logged in
	if (to.meta.requiresAuth && !$store.isLoggedIn) {
		// redirect to login page, save the current url as referrer
		return {
			path: '/Account/Login',
			query: { referrer: to.fullPath },
		}
	} else if (to.meta.requiresAnonymous && $store.isLoggedIn) {
		// redirect to referrer or home page
		const referrer = to.query.referrer;
		if (referrer && typeof referrer === 'string' && referrer.indexOf('/') === 0 && referrer.indexOf('//') !== 0) {
			const to = router.resolve(referrer);
			if (to && !to.meta.is404) {
				return referrer;
			}
		}
		return '/';
	}

	// stop impersonation automatically
	if (to.meta.requiresAdmin && $store.isLoggedIn && $store.isUser && $store.user.isAdmin) {
		companyId.set(null);
		await companyId.writeToIdbAndBroadcast();
		await $store.refresh();
	}

	// permissions
	if (to.meta.requiresCompanyUser && !$store.isUser) {
		return from.meta.isLoginPage ? '/' : '/AccessDenied';
	}
	if (to.meta.requiresOperator && !$store.isOperator) {
		return from.meta.isLoginPage ? '/' : '/AccessDenied';
	}
	if (Array.isArray(to.meta.permissions) && to.meta.permissions.length > 0) {
		for (let i = 0; i < to.meta.permissions.length; i++) {
			if (!$store.userHasThisPermission(to.meta.permissions[i])) {
				return from.meta.isLoginPage ? '/' : '/AccessDenied';
			}
		}
	}
	if (Array.isArray(to.meta.modules) && to.meta.modules.length > 0) {
		for (let i = 0; i < to.meta.modules.length; i++) {
			if (!$store.userHasThisModule(to.meta.modules[i])) {
				return from.meta.isLoginPage ? '/' : '/AccessDenied';
			}
		}
	}

	// if this route requires that the user be punched in and the user is not
	if (to.meta.requiresPunchIn && !$store.user.isPunchedIn && !$store.user.isImpersonating && $store.settings && $store.settings.requireOperatorPunch) {
		// redirect to punch page, save the current url as referrer
		$store.addMessage("Please punch in before starting work.", MessageType.warning);
		if (to.meta.requiresOperator) {
			return {
				path: '/Portal/Operator/Timesheets/Punch',
				query: { referrer: to.fullPath },
			}
		} else {
			return {
				path: '/Portal/Timesheets/Punch',
				query: { referrer: to.fullPath },
			}
		}
	}

	$store.refreshCacheIfNeeded();
	// Update SW on each SPA route
	if ($store.isOnline && navigator.serviceWorker) {
		navigator.serviceWorker.ready.then(worker => worker.update());
	}
});

router.afterEach((to) => {
	const $store = useStore();
	let appName = 'Septic Biz Man';
	if ($store.environment === 'development') {
		appName += ' - Development'
	} else if ($store.environment === 'testing') {
		appName += ' - Testing'
	} else if ($store.environment === 'staging') {
		appName += ' - Staging'
	}
	document.title = (to.meta.title ? to.meta.title + ' | ' : '') + appName;
});

router.onError(handleRouterError);

export default router;
