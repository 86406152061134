<template>
	<transition
		name="toggle-height"
		@before-enter="beforeEnter"
		@enter="enter"
		@after-enter="afterEnter"
		@before-leave="beforeLeave"
		@leave="leave">
		<slot />
	</transition>
</template>

<script>
export default {
	data() {
		return {
			duration: '',
			timing: '',
		}
	},
	methods: {
		beforeEnter(el) {
			el.style.height = '0';
			el.style.overflow = 'hidden';
		},
		enter(el) {
			this.setOptions(el.scrollHeight, true);
			this.$nextTick(() => {
				el.style.height = `${el.scrollHeight}px`;
			});
		},
		afterEnter(el) {
			el.style.height = 'auto';
			el.style.overflow = 'visible';
		},
		beforeLeave(el) {
			this.setOptions(el.scrollHeight, false);
			el.style.height = `${el.scrollHeight}px`;
			el.style.overflow = 'hidden';
		},
		leave(el) {
			el.style.height = '0';
		},
		setOptions(height, isEnter) {
			if (height < 500) {
				this.duration = '0.4s';
			} else if (height < 1000) {
				this.duration = '0.5s';
			} else if (height < 5000) {
				this.duration = '0.7s';
			} else if (height < 10000) {
				this.duration = '1s'
			} else {
				this.duration = '1.3s'
			}

			if (height >= 3000) {
				isEnter ? this.timing = 'ease-in' : this.timing = 'ease-out';
			} else {
				this.timing = 'ease'
			}
		},
	},
}
</script>

<style scoped>
.toggle-height-enter-active,
.toggle-height-leave-active {
	transition: height v-bind('duration') v-bind('timing');
}

.animate-height {
	will-change: height;
	transform: translateZ(0);
	backface-visibility: hidden;
	perspective: 1000px;
	/* Overflow set to hidden^ during animation only for tooltips, hover states, etc. */
	overflow: visible;
}
</style>
