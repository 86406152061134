<template>
	<button :class="buttonClass" type="submit" :disabled="disabled || loading">
		<span class="button-text" :class="{ hidden: loading }"><slot>Submit</slot></span>
		<loading-indicator type="dots" v-if="loading" />
	</button>
</template>

<script>
export default {
	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			required: true,
		},
		buttonClass: {
			type: String,
			default: 'btn-confirm',
		}
	},
};
</script>
