import companyId from '@/api/modules/companyId';
import { useStore } from '@/store';
import { inject } from 'vue';

export function useJoinCompany() {
	const store = useStore();
	const confirmDialog = inject('confirmDialog');

	async function joinCompany(id) {
		if (store.user.companyId !== id && store.localChangesCount > 0) {
			const confirmed = await confirmDialog.value.openDialog(
				`You have ${store.localChangesCount} unsynced change(s). Are you sure you want to switch companies? You will lose any unsynced changes.`,
				{
					type: 'warning',
					confirmText: 'Leave company',
				}
			);
			if (!confirmed) return false;
		}

		companyId.set(id);
		await companyId.writeToIdbAndBroadcast();
		await store.refresh();
		return true;
	}

	return {
		joinCompany
	};
}
