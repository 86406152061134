<template>
	<!-- Follows both HTML structure and css styles located on index.html -->
	<div class="page-loader-contain">
		<div class="spinner">
			<div class="spinner-item"></div>
			<div class="spinner-item"></div>
			<div class="spinner-item"></div>
		</div>
		<svg class="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 231.23 254.73"><path d="M228,60.09a10.5,10.5,0,0,0-6.54-7.7h0c-23.76-9.09-46.68-14-68.12-14.48h-.06a1.45,1.45,0,0,0-.73,2.72c15.82,8.79,24.19,17.89,25.38,19.23l0,.06a8.57,8.57,0,0,0,4.67,2.7,207.2,207.2,0,0,1,25.68,7.31c1.6,11.46,4.17,38.9-2.27,68.5-10.81,49.73-41.24,81.73-90.45,95.19-49-13.4-79.38-45.2-90.31-94.57-6.57-29.7-4-57.56-2.39-69.12C42,63.19,60.3,59.54,77.42,59h0c1.13,0,2.26-.06,3.38-.07h2.69c.59,0,1.17,0,1.76,0l1,0,1.88.07.82,0,2.67.17.27,0c.79.06,1.59.13,2.38.21l.87.09,1.77.19,1,.12,1.68.23,1,.13,1.78.28.8.13c.85.15,1.7.3,2.54.47l.13,0c.8.15,1.59.32,2.38.49l.81.19,1.68.4.92.23,1.59.41.9.25c.56.15,1.11.32,1.66.48l.77.23c.8.24,1.6.5,2.39.76s1.38.47,2.06.71l.68.25,1.3.48.78.31,1.14.45.81.33,1.05.44.79.35,1,.44.77.36.95.44.74.37.91.44.72.37.86.45c.24.12.47.24.69.37l.83.45.66.36.8.45.62.36.77.46.58.35.74.46.54.33.73.48c.16.1.33.2.48.31l.74.49L142,76l.79.55.29.2,1,.73-.19.16a51,51,0,1,1-54.75-1.15,1.45,1.45,0,0,0-.67-2.69q-3.27-.19-6.65-.19A136.68,136.68,0,0,0,62.6,75a8.57,8.57,0,0,0-5.8,3.57,72,72,0,1,0,110-9.22c-1.84-2-14.95-15.52-39.77-24.13a137.1,137.1,0,0,0-44.59-7.32V21h97.37a2,2,0,0,0,2-2V2.05a2,2,0,0,0-2-2H50.93a2.05,2.05,0,0,0-2,2.05V19a2,2,0,0,0,2,2H61.39V39.13A219.18,219.18,0,0,0,9.77,52.39h0a10.5,10.5,0,0,0-6.54,7.7c-.33,1.6-7.94,39.78,1.41,82.81,8.74,40.19,34.3,91.91,106.32,111.23a18.36,18.36,0,0,0,9.31,0c72-19.32,97.58-71,106.32-111.23C235.94,99.87,228.33,61.69,228,60.09Z" style="fill: #2b4943"/><g><path d="M80.24,139.45a40.39,40.39,0,0,0,70.76,0Z" style="fill: #658a8e"/><path d="M154.58,109.21H76.67a40,40,0,0,0-1.46,10.73,40.65,40.65,0,0,0,1.31,10.24h78.2a40.27,40.27,0,0,0-.14-21Z" style="fill: #658a8e"/></g></svg>
	</div>
</template>
