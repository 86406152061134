<template>
	<teleport to="#teleported">
		<div class="company-change" id="company-change-overlay">
			<transition name="toggle-overlay">
				<div v-if="isOpen" class="screen-overlay"></div>
			</transition>

			<transition name="toggle-dialog">
				<div v-if="isOpen" class="dialog text-center">
					<h4>You've switched companies</h4>
					<p>You&rsquo;ve switched companies in another tab or window and this page is now out-of-date.</p>
					<p>
						You can reload this page and continue to the new company, or stay on this page and switch back to this company.
						<br /> <strong>Any unsaved data will be lost.</strong>
					</p>
					<div class="dialog-buttons">
						<button type="button" class="btn-grey full-width" @click="reload">Reload & continue<br />to new company</button>

						<button type="button" class="btn-primary full-width" @click="switchToPreviousCompany">
							Stay & switch back<br />to this company
						</button>
					</div>
				</div>
			</transition>
		</div>
	</teleport>
</template>

<script setup>
import { useJoinCompany } from '@/helpers/joinCompany';
import { useStore } from '@/store';
import { computed, onUnmounted, watch } from 'vue';

const store = useStore();
const isOpen = computed(() => store.showCompanyChangeOverlay);
const { joinCompany } = useJoinCompany();
async function switchToPreviousCompany() {
	const joined = await joinCompany(store.user.companyId);
	if (joined) {
		store.showCompanyChangeOverlay = false;
	}
}
function reload() {
	window.location.reload();
}
function setReadOnly(isReadOnly) {
	const elements = document.querySelectorAll('#app, #teleported > :not(#company-change-overlay)');
	for (const element of elements) {
		if (isReadOnly) {
			element.setAttribute('inert', '');
		} else {
			element.removeAttribute('inert');
		}
	}
}
watch(isOpen, () => setReadOnly(isOpen.value), { immediate: true });
onUnmounted(() => setReadOnly(false));
</script>

<style lang="scss" scoped>
@import "../../scss/_variables";

.company-change {
	position: fixed;
	z-index: 9999;

	.screen-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
		z-index: 9999;
		display: block;
		transition: all 0.3s ease;
	}

	.dialog {
		position: fixed;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		max-width: 550px;
		width: 95%;
		margin: 0 auto;
		background: #fff;
		padding: 20px;
		max-height: 95%;
		overflow-y: auto;
		z-index: 99999;

		@include media-query-max-width(460) {
			padding: 15px;
		}

		&.show-overflow {
			overflow: visible;
		}

		&.dialog-email {
			max-width: 650px;
		}

		&.dialog-email-history {
			max-width: 1100px;

			.email-history-table {
				thead {
					top: -20px;
					background: white;
				}

				tbody {
					tr {
						td {
							.email-list-contain {
								.email-list-item {
									background: darken(whitesmoke, 2%);
								}
							}
						}

						&:nth-of-type(even) {
							background: $ltGrey;

							td {
								.email-list-contain {
									.email-list-item {
										background: whitesmoke;
									}
								}
							}
						}
					}
				}
			}
		}

		ul,
		ol {
			list-style-position: inside;
			margin: 10px 0;
			padding: 0;
			margin: 0;
			list-style-type: none;

			li {
				padding: 5px 0;
				line-height: 1.3;

				p {
					padding: 0;
					margin: 0 0 7px 0;
				}
			}
		}

		ol {
			counter-reset: item;

			li {
				padding-left: 25px;
				counter-increment: item;

				&::before {
					content: counter(item) ".";
					color: $primary;
					margin-left: -25px;
					width: 20px;
					font-style: normal;
					font-weight: 700;
					position: absolute;
				}
			}
		}

		h4,
		p {
			line-height: 1.3;
		}

		.title-row {
			margin-bottom: 25px;

			h2 {
				font-size: 22px;
				font-style: normal;
				font-weight: 400;
				margin: 0;
				letter-spacing: 0.06em;
			}
		}

		.close {
			cursor: pointer;
			transition: all .3s ease;

			&:hover {
				opacity: 0.7;
			}
		}

		@include inputList() {
			border: 1px solid transparent;
			background: $ltGrey;

			&:focus {
				border: 1px solid $primary;
			}

			&:disabled {
				cursor: not-allowed;
				background-color: lighten($dkGrey, 10%);
				overflow: hidden;
				text-overflow: ellipsis;
			}

			&[readonly] {
				cursor: help;
				background-color: lighten($dkGrey, 10%);
				overflow: hidden;
				text-overflow: ellipsis;
			}

			&.dark {
				background: $b;
				color: white;
			}

			&.error {
				border: 1px solid $red;
			}
		}

		.faux-select-contain {
			.faux-select-field {
				border: 1px solid transparent;
				background: $ltGrey;
			}

			.multi-select-drop-down {
				.inner {
					background: white;
					border-color: $ltGrey;
				}
			}
		}

		select {
			option {
				background: white;
			}

			&.dark {

				option {
					background: $b;
				}
			}
		}

		.select-input-contain {

			&::after {
				border-top: 10px solid $primary;
			}

			&.dark {
				&::after {
					border-top: 10px solid $ltGrey;
				}

				select {
					background: $b;
					color: white;
				}
			}
		}

		.number-input-contain {
			position: relative;
			flex-grow: 1;

			&.dark {
				.step-up {
					border-bottom: 9px solid $ltGrey;
				}

				.step-down {
					border-top: 9px solid $ltGrey;
				}

				input {
					background: $b;
					color: white;
				}
			}
		}

		.dialog-buttons {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 20px;

			button,
			[class*=btn] {
				margin: 0;
			}
		}
	}

	.toggle-dialog {

		&-leave-active,
		&-enter-active {
			transition: all 0.3s ease;
		}

		&-enter-from,
		&-leave-to {
			transform: translate(-50%, -50%) scale(0.7);
			opacity: 0;
		}
	}

	.toggle-overlay {

		&-leave-active,
		&-enter-active {
			transition: all 0.3s ease;
		}

		&-enter-from,
		&-leave-to {
			opacity: 0;
		}
	}
}
</style>
