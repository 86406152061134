<template>
	<router-view />
	<dialog-confirm ref="confirmDialog" />
	<dialog-confirm-delete ref="deleteDialog" />
	<app-icons />
	<app-messages />
	<refresh-service-worker />
	<company-change-overlay />
</template>

<script setup>
import AppIcons from '@/views/_shared/AppIcons.vue';
import AppMessages from '@/views/_shared/AppMessages.vue';
import DialogConfirm from '@/views/_shared/DialogConfirm.vue';
import DialogConfirmDelete from '@/views/_shared/DialogConfirmDelete.vue';
import RefreshServiceWorker from '@/views/_shared/RefreshServiceWorker.vue';
import CompanyChangeOverlay from '@/views/_shared/CompanyChangeOverlay.vue';
import { nextTick, onMounted, provide, ref } from 'vue';

const confirmDialog = ref(null);
provide('confirmDialog', confirmDialog);

const deleteDialog = ref(null);
provide('deleteDialog', deleteDialog);

onMounted(async () => {
	await nextTick();
	const appLoader = document.getElementById('app_loader');
	if (appLoader) {
		appLoader.classList.add('hide');
		setTimeout(() => {
			appLoader.remove();
		}, 500);
	}
});
</script>
