<template>
	<router-link class="back-btn title-icon-contain" :to="backToPath" @click="backClick" :title="'Back to ' + backToText.toLowerCase()">
		<svg class="icon">
			<use xlink:href="#i-back"></use>
		</svg>
		<div class="back-to">
			<span>Back to&hellip;</span>
			<strong>{{ backToText }}</strong>
		</div>
	</router-link>
</template>

<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';

const props = defineProps({
	to: {
		type: String,
		required: true
	},
	backText: {
		type: String,
		required: true
	},
});

function backClick(e) {
	if (lastPage.value) {
		e.preventDefault();
		$router.go(-1);
	}
}

const $router = useRouter();
const lastPage = computed(() => {
	const from = $router.options.history.state.back ? $router.resolve($router.options.history.state.back) : null;
	return from === null || from.meta.isErrorPage || from.fullPath === '/' ? null : from;
});
const backToPath = computed(() => lastPage.value ? lastPage.value.fullPath : props.to);
const backToText = computed(() => lastPage.value ? lastPage.value.meta.title ?? 'Last page' : props.backText);
</script>
