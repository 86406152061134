<template>
	<footer class="dark-pattern-bg app-padding-x">
		<div class="app-wrapper">
			<h2 v-if="showCompany">{{ $store.settings.companyName }}</h2>
			<div v-if="!altLogo" class="logo-contain">
				<router-link :to="logoLink" title="Septic Biz Man">
					<svg class="icon">
						<use xlink:href="#logo-light"></use>
					</svg>
				</router-link>
			</div>
			<img v-else src="@/images/inetWebMarketingLogo.png" />
			<div class="copy-ver-wrap">
				<span class="copyright">Copyright {{ year }} All Rights Reserved.</span>
				<span class="app-ver">App v{{ version }}</span>
			</div>
		</div>
	</footer>
</template>

<script setup>
import { useStore } from '@/store';
import { DateTime } from 'luxon';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
const year = DateTime.now().year;
const $store = useStore();
const version = __APP_VERSION__;
const $router = useRouter();

const matchedRoute = $router.currentRoute.value.matched[0].path;
const logoLink = computed(() => matchedRoute === '/Portal' || matchedRoute === '/Portal/Operator' ? matchedRoute : '/');

defineProps({
	altLogo: {
		type: Boolean,
		default: false,
	},
	showCompany: {
		type: Boolean,
		default: false,
	},
});
</script>
