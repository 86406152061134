<template>
	<div v-if="lastPage">
		<button class="btn-primary large-btn full px-2" @click="$router.go(-1)">Go back to {{ lastPage.meta.title ? lastPage.meta.title : 'previous page' }}</button>
	</div>
	<router-link to="/" :class="lastPage ? 'btn-link mt-1' : 'btn-primary large-btn full px-2'">Back to {{ homePage.meta.title ?? 'Home' }}</router-link>
</template>

<script setup>
import { homePage } from '@/router';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

const $router = useRouter();

const lastPage = computed(() => {
	const from = $router.options.history.state.back ? $router.resolve($router.options.history.state.back) : null;
	return from === null || from.meta.isErrorPage || from.fullPath === '/' || from.meta.isHomePage ? null : from;
});
</script>
