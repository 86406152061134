import Permission from '@/models/Permission';
import { handleImportError, parseIntProp } from '../_helpers';

export default {
	path: '/Admin',
	component: () => import('./admin-imports').then(x => x._AdminApp).catch(handleImportError),
	meta: { requiresAuth: true, requiresAdmin: true, permissions: [Permission.admin], blockOffline: true, appName: 'admin', lockOnCompanyChange: true, },
	children: [
		{
			path: '',
			redirect: '/Admin/Companies'
		},
		{
			path: 'Companies',
			component: () => import('./admin-imports').then(x => x.CompaniesPage).catch(handleImportError),
			meta: { title: 'Companies', isHomePage: true, },
		},
		{
			path: 'Users',
			component: () => import('./admin-imports').then(x => x.UsersPage).catch(handleImportError),
			meta: { title: 'Users', permissions: [Permission.admin, Permission.adminUsers], },
		},
		{
			path: 'Users/:id(\\d+)',
			component: () => import('./admin-imports').then(x => x.UserEditPage).catch(handleImportError),
			props: route => ({ id: parseIntProp(route.params.id) }),
			meta: { title: 'User Edit', permissions: [Permission.admin, Permission.adminUsers], },
		},
		{
			path: 'Roles',
			component: () => import('./admin-imports').then(x => x.RolesPage).catch(handleImportError),
			meta: { title: 'Roles', permissions: [Permission.admin, Permission.adminRoles], },
		},
		{
			path: 'Roles/:id(\\d+)',
			component: () => import('./admin-imports').then(x => x.RoleEditPage).catch(handleImportError),
			props: route => ({ id: parseIntProp(route.params.id) }),
			meta: { title: 'Role Edit', permissions: [Permission.admin, Permission.adminRoles], },
		},
		{
			path: 'Emails',
			component: () => import('./admin-imports').then(x => x.EmailsPage).catch(handleImportError),
			props: route => ({
				pageNumber: parseIntProp(route.query.pageNumber),
				pageSize: parseIntProp(route.query.pageSize),
			}),
			meta: { title: 'Emails', permissions: [Permission.admin], },
		},
		{
			path: 'Utilities',
			component: () => import('./admin-imports').then(x => x.UtilitiesPage).catch(handleImportError),
			meta: { title: 'Utilities', permissions: [Permission.admin], },
		},
		{
			path: 'Subscriptions',
			component: () => import('./admin-imports').then(x => x.SubscriptionsPage).catch(handleImportError),
			meta: { title: 'Subscriptions', permissions: [Permission.admin], },
		},
		{
			path: 'Subscriptions/:id(\\d+)',
			component: () => import('./admin-imports').then(x => x.SubscriptionEditPage).catch(handleImportError),
			props: route => ({ id: parseIntProp(route.params.id) }),
			meta: { title: 'Subscription Edit', permissions: [Permission.admin], },
		},
	]
};
