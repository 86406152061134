<template>
	<label class="check-radio-group" tabindex="0" :class="[inputType, { checked: checked, disabled: disabled, slider: sliderUI }]">
		<slot />

		<span class="check-radio-custom" :class="[inputType, { slider: sliderUI }]">
			<span class="icon circle" v-if="isRadio || sliderUI"></span>
			<svg class="icon" v-else>
				<use href="#checkbox-check"></use>
			</svg>
		</span>

		<span v-if="label || $slots.label" class="check-radio-text">
			<template v-if="label">{{ label }}</template>
			<slot v-else name="label" />
		</span>
	</label>
</template>

<script setup>
const props = defineProps({
	label: { type: String },
	checked: { type: Boolean },
	disabled: { type: Boolean },
	isRadio: { type: Boolean, default: false },
	isSlider: { type: Boolean, default: false }
});

const inputType = props.isRadio ? 'radio' : 'checkbox';
const sliderUI = !props.isRadio && props.isSlider;
</script>

<style lang="scss" scoped>
@import '@/scss/variables';

:deep(input) {
	position: absolute;
	pointer-events: none;
	opacity: 0;
}

// Custom checkbox/radio input group
.check-radio-group {
	--bg-color: #{$grey};
	--border-color: #{$grey};
	--check-color: white;
	--input-size: 28px;
	display: inline-flex;
	align-items: center;
	margin: 7px 0;
	gap: 10px;
	cursor: pointer;

	&:focus-visible:not(.disabled),
	&:hover:not(.disabled) {
		--border-color: #{$primary};

		&.checked {
			&.checkbox {
				--border-color: #{darken($grey, 25%)};
			}
		}
	}

	// Checked state
	&.checked {
		&.checkbox {
			--bg-color: #{$b};
		}

		&.radio {
			--border-color: #{$primary};
			--bg-color: transparent;
		}

		&.slider {
			--bg-color: #{$primary};

			.check-radio-custom {
				.icon {
					transform: translateX(calc(100% + (var(--slider-offset) * 2)));
				}
			}

		}

		.check-radio-custom {
			.icon {
				transform: translate(-50%, -50%) scale(1);
				opacity: 1;
			}
		}
	}

	&.slider {
		margin: 0;
	}

	&.disabled {
		cursor: not-allowed;
	}

	&.input-height {
		min-height: 48px;
		margin: 0;
	}

	&.green-check {
		--check-color: #{$green};
	}

	&.small {
		--input-size: 24px;
	}

	.check-radio-custom {
		position: relative;
		display: block;
		flex-shrink: 0;
		line-height: 0;
		width: var(--input-size);
		height: var(--input-size);
		background-color: var(--bg-color);
		border: 3px solid var(--border-color);
		transition: all .3s ease;

		&.radio {
			border-radius: 50%;
		}

		&.slider {
			--input-size: 26px;
			--slider-offset: 3px;
			width: calc(var(--input-size) * 2);
			border-radius: 14px;
			border: none;
			padding: var(--slider-offset);

			.icon {
				position: static;
				transform: none;
				display: block;
				opacity: 1;
				transition: all .3s ease;

				&.circle {
					height: 100%;
					width: calc(50% - var(--slider-offset));
					background: white;
				}
			}
		}

		.icon {
			@include absolute-center-xy;
			transform: translate(-50%, -50%) scale(0);
			opacity: 0;
			height: calc(100% - 5px);
			width: calc(100% - 5px);
			transition: inherit;
			color: var(--check-color);
			line-height: 0;

			&.circle {
				height: calc(100% - 4px);
				width: calc(100% - 4px);
				background: $primary;
				border-radius: 50%;
			}
		}
	}

	.check-radio-text {
		font-style: normal;
		font-weight: 700;
		flex-grow: 1;

		&::first-letter {
			text-transform: capitalize;
		}
	}

	label {
		margin-bottom: 0;
		cursor: pointer;
	}

	span.text-danger {
		padding: 5px 7px;
		margin: 0;
	}
}
</style>
