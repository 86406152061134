import accountApi from '@/api/modules/account';
import companyId from '@/api/modules/companyId';
import permissionsApi from '@/api/modules/permissions';
import settingsApi from '@/api/modules/settings';
import timeClockPunchesApi from '@/api/modules/timeClockPunches';
import { setLuxonTimeZone } from '@/helpers/helpers';
import { setUser as setLoggingUser } from '@/helpers/logging';
import { getIdb, localChangesInUse, setLocalChangesInUse } from '@/idb';
import Message from '@/models/Message';
import MessageType from '@/models/MessageType';
import Permission from '@/models/Permission';
import Settings from '@/models/Settings';
import UserSelf from '@/models/UserSelf';
import { DateTime } from 'luxon';
import { defineStore, mapStores } from 'pinia';
import { computed, ref } from 'vue';

let env = 'production';
if (window.location.hostname === 'localhost' || /\.ngrok((-free)?\.(app|dev)|\.io)$/.test(window.location.hostname)) {
	env = 'development'
} else if (/^nitrogen\d\.inetwd\.com$/.test(window.location.hostname)) {
	env = 'testing'
} else if (/^www(blue|green)\.\w+\.\w+$/.test(window.location.hostname)) {
	env = 'staging'
}

const _useStore = defineStore('general', () => {
	const debugging = env === 'development' && import.meta.env.MODE === 'development';
	const environment = env;
	const isOnline = ref(true);

	const user = ref(new UserSelf());
	const isLoggedIn = computed(() => user.value.id > 0);
	const isUser = computed(() => isLoggedIn.value && user.value.companyId > 0);
	const isOperator = computed(() => isUser.value && user.value.operator && userHasThisPermission(Permission.companyOperator));
	const showCompanyChangeOverlay = ref(false);

	function userHasThisPermission(permission) {
		return isLoggedIn.value && (
			user.value.permissions.includes(permission) ||
			user.value.permissions.includes(Permission.adminAccessAll) ||
			(user.value.permissions.includes(Permission.companyAccessAll) && !permissionsApi.isAdminPermission(permission))
		);
	}

	function userHasThisModule(module) {
		return isLoggedIn.value && user.value.modules.includes(module);
	}

	async function refresh() {
		const oldUser = JSON.parse(JSON.stringify(user.value));
		await refreshAuth();
		const forceClear = ['id', 'companyId'].some(prop => user.value[prop] !== oldUser[prop]);
		if (forceClear || ['operator', 'isPunchedIn', 'isAdmin', 'isImpersonating'].some(prop => user.value[prop] !== oldUser[prop])) {
			await Promise.all([
				refreshSettings(),
				refreshLastTimeClockPunch(),
				refreshCacheIfNeeded(forceClear),
			]);
		}
		return forceClear;
	}

	async function refreshAuth() {
		await companyId.setFromIdb();
		const u = (await accountApi.getSelf()) ?? new UserSelf();
		if (companyId.get() === null && u.companyId !== null) {
			u.companyId = null;
		}
		user.value = u;
		setLoggingUser(user.value);
		await setLocalChangesInUse(isOperator.value);
	}


	const lastTimeClockPunch = ref(null);
	const lastTimeClockPunchLoaded = ref(false);

	async function refreshLastTimeClockPunch() {
		if (isUser.value && !user.value.isImpersonating) {
			lastTimeClockPunch.value = await timeClockPunchesApi.getMyLastPunch();
		} else {
			lastTimeClockPunch.value = null;
		}
		lastTimeClockPunchLoaded.value = true;
	}


	const settings = ref(null);

	async function refreshSettings() {
		const s = isUser.value ? await settingsApi.getSettings() : null;

		if (settings !== null && s instanceof Settings) {
			settings.value = s;
		} else {
			settings.value = null;
		}

		// set time zone
		setLuxonTimeZone(settings.value?.timeZone ?? null);
	}


	const lastCacheRefresh = ref(null);
	const localChangesSyncing = ref(false);
	const localChangesCount = ref(0);

	async function refreshCacheIfNeeded(forceClear) {
		const idb = localChangesInUse.value ? await getIdb() : null;
		if (forceClear) {
			lastCacheRefresh.value = null;
			localChangesSyncing.value = false;
			localChangesCount.value = 0;
		}
		if (idb) {
			if (!lastCacheRefresh.value) {
				const dIso = await idb.get('localInfo', 'lastCacheRefresh');
				const d = dIso ? DateTime.fromISO(dIso) : null;
				lastCacheRefresh.value = d && d.isValid ? d : null;
			}
			if (navigator.serviceWorker && (!lastCacheRefresh.value || lastCacheRefresh.value < DateTime.now().minus({ hours: 1 }))) {
				navigator.serviceWorker.ready.then(function (worker) {
					worker.active.postMessage('sync-local-changes');
				});
			}
		} else {
			lastCacheRefresh.value = null;
		}
	}


	const installPrompt = ref(null);
	const showInstallBanner = ref(false);
	const showInstallButton = ref(false);
	const serviceWorkerUpdate = ref(false);

	function setCustomPwaBanner(status) {
		if (status && localStorage.getItem('pwaUserChoice') != 'no') {
			showInstallBanner.value = true;
		} else {
			showInstallBanner.value = false;
		}
	}

	function setPwaPrompt(event) {
		if (event != null) {
			installPrompt.value = event;
		}
	}


	const messages = ref([]);

	function removeMessage(messageId) {
		const index = messages.value.findIndex(x => x.id === messageId);
		if (index >= 0) {
			messages.value.splice(index, 1);
		}
	}

	function addMessage(messageText, type = MessageType.info, autoClose = true) {
		if (!(typeof messageText === 'string')) { return; }
		messageText = messageText.trim();
		if (!messageText) { return; }

		const currentId = messages.value.length > 0 ? messages.value.reduce((id, x) => Math.max(id, x.id), 0) : 0;
		const message = new Message();
		message.id = currentId + 1;
		message.type = MessageType.isValid(type) ? type : MessageType.info;
		message.message = messageText;
		message.autoClose = !!autoClose;

		if (messages.value.findIndex(x => x.id === message.id) >= 0) { return; }
		messages.value.push(message);
		if (autoClose) {
			setTimeout(() => {
				removeMessage(message.id);
				// 1s delay for animate out:
			}, Message.AUTO_CLOSE_DELAY + 1000);
		}
	}

	return {
		debugging,
		environment,
		isOnline,

		user,
		isLoggedIn,
		isUser,
		isOperator,
		showCompanyChangeOverlay,
		userHasThisPermission,
		userHasThisModule,
		refresh,
		refreshAuth,

		lastTimeClockPunch,
		lastTimeClockPunchLoaded,
		refreshLastTimeClockPunch,

		settings,
		refreshSettings,

		lastCacheRefresh,
		localChangesSyncing,
		localChangesCount,
		refreshCacheIfNeeded,

		installPrompt,
		showInstallBanner,
		showInstallButton,
		serviceWorkerUpdate,
		setCustomPwaBanner,
		setPwaPrompt,

		messages,
		removeMessage,
		addMessage,
	};
});

let _storeInUse = false;
export function storeInUse() {
	return _storeInUse;
}

export function useStore() {
	_storeInUse = true;
	return _useStore();
}

export function mapStore() {
	const { generalStore } = mapStores(_useStore);
	return { $store: generalStore };
}
