/**
 *
 * @param {Object} options
 * @param {import('vue-router').Router} options.router
 */
export function initGoogleAnalytics({ router }) {
	router.isReady().then(() => {
		router.afterEach((to, from, failure) => {
			if (failure) { return; }
			const gtag = window.gtag;
			if (typeof gtag === 'function') {
				gtag('event', 'page_view', {
					page_title: to.meta.title ?? '',
					page_location: self.location.origin + to.fullPath,
					app_version: __APP_VERSION__,
				});
			}
		});
	})
}
