<template>
	<div class="app-public app-section">
		<header-partial class="app-public-header" />
		<main role="main" class="app-padding-x app-padding-y">
			<div class="app-wrapper">
				<h1>You are offline</h1>
				<p v-if="referrer && !$store.isLoggedIn">You must be connected to the internet to login.</p>
				<p v-else>The page you requested is not available while offline.</p>
				<br>
				<back-to-buttons />
			</div>
		</main>
		<footer-partial />
	</div>
</template>

<script setup>
import { propWatch } from '@/helpers/propWatch.js';
import { useStore } from '@/store';
import BackToButtons from '@/views/_shared/BackToButtons.vue';
import FooterPartial from '@/views/_shared/FooterPartial.vue';
import HeaderPartial from '@/views/_shared/HeaderPartial.vue';
import { useRouter } from 'vue-router';

const props = defineProps({
	referrer: {
		type: String,
	}
});
const $store = useStore();
const $router = useRouter();

propWatch([() => props.referrer, () => $store.isOnline], () => {
	if ($store.isOnline && props.referrer && props.referrer.indexOf('/') === 0 && props.referrer.indexOf('//') !== 0) {
		const to = $router.resolve(props.referrer);
		if (to && !to.meta.is404) {
			$router.replace(to);
		}
	}
}, { immediate: true });
</script>
