<template>
	<div v-show="messages.length > 0" class="app-message-float" :class="{ overflow: overflow }">
		<div class="inner" ref="messageContainer">
			<app-message v-for="message in messages" :key="message.id" :message="message" />
		</div>
	</div>
</template>

<script setup>
import { computed, nextTick, ref, watch } from 'vue';
import { useStore } from '@/store';
import AppMessage from './AppMessage.vue';

const messageContainer = ref(null);
const $store = useStore();
const messages = computed(() => $store.messages);
const overflow = ref(false);

watch(messages.value, async () => {
	function isOverflown(element) {
		return element.scrollHeight > element.clientHeight;
	}
	await nextTick();
	overflow.value = isOverflown(messageContainer.value);
});
</script>
