import { isAborted, isOfflineResponse } from '@/api/_helpers';
import MessageType from '@/models/MessageType';
import $router from '@/router';
import { useStore } from '@/store';

export const GENERIC_HTTP_ERROR = 'A problem has occurred. Please refresh the page and try again. If the problem persists, contact support.';
export const GENERIC_VALIDATION_ERROR = 'One or more validation errors occurred.';

/**
 * Handle common HTTP fetch repsponse status codes
 * @param {Response} response
 */
export async function handleHttpError(e) {
	if (isAborted(e)) return;
	console.error(e);
	const $store = useStore();

	if (!(e instanceof Response)) {
		$store.addMessage(GENERIC_HTTP_ERROR, MessageType.error);
		return;
	}
	const response = e;
	const status = response.status;
	if (isOfflineResponse(response)) {
		$store.addMessage('The request could not be completed because this device appears to be offline.', MessageType.error);
	} else if (status >= 100 && status <= 299) {
		// info and successful responses
		return;
	} else if (status === 400) {
		// bad request - generic message
		$store.addMessage(GENERIC_VALIDATION_ERROR, MessageType.error);
	} else if (status === 401) {
		// unauthenticated - user must log in
		await $store.refresh();
		$store.addMessage('Your session has expired, please login again.', MessageType.error);
		return $router.push('/');
	} else if (status === 403) {
		// forbidden - logged in, action not permitted
		$store.addMessage('Access denied. Your account does not have permission to do this.', MessageType.error);
	} else if (status === 404 || status === 410) {
		// not found
		$store.addMessage('The information you requested was not found.', MessageType.error);
	} else if (status === 408) {
		// timeout
		$store.addMessage('The request has timed out because the server took too long to respond.', MessageType.error);
	} else {
		// treat all other responses as general errors
		$store.addMessage(GENERIC_HTTP_ERROR, MessageType.error);
	}
}

export async function processValidationErrors(response, keys) {
	const $store = useStore();
	const responseData = await response.json();
	cleanErrors(responseData);
	const serverErrors = {};
	for (let i = 0; i < keys.length; i++) {
		serverErrors[keys[i]] = undefined;
	}
	if (typeof responseData === 'object') {
		if (responseData.title !== GENERIC_VALIDATION_ERROR) {
			$store.addMessage(responseData.title, MessageType.error);
		}
		if ('errors' in responseData && typeof responseData.errors === 'object') {
			for (const key in responseData.errors) {
				if (Object.hasOwnProperty.call(responseData.errors, key)) {
					const message = responseData.errors[key].join(' ');
					if (keys.includes(key)) {
						serverErrors[key] = message;
					} else {
						$store.addMessage(message, MessageType.error);
					}
				}
			}
		}
	} else {
		$store.addMessage(GENERIC_VALIDATION_ERROR, MessageType.error);
	}
	return serverErrors;
}

export function cleanErrors(responseData) {
	/* useful: {title,detail,errors?}, less useful: {type,status,instance} */
	/* errors is an object of key:array */
	if (responseData.errors && typeof responseData.errors === 'object') {
		// System.Text.Json version
		// let jsonDetailPattern = / Path: \$\.\w+ \| LineNumber: \d+ \| BytePositionInLine: \d+\.$/i;
		// Newtonsoft.Json version
		const jsonDetailPattern = / Path '[^']+', Line \d+, Position \d+\.$/i;
		// Path 'contacts[0].emailAddresses[1].sortOrder', line 1, position 1213.
		for (const key in responseData.errors) {
			if (Object.hasOwnProperty.call(responseData.errors, key)) {
				const element = responseData.errors[key];
				for (let i = 0; i < element.length; i++) {
					element[i] = element[i].replace(jsonDetailPattern, '');
				}
			}
		}
		const originalKeys = Object.keys(responseData.errors);
		for (let i = 0; i < originalKeys.length; i++) {
			const key = originalKeys[i];
			if (Object.hasOwnProperty.call(responseData.errors, key)) {
				// cleanup key
				let newKey = key.startsWith('$.') ? key.substring(2) : key;
				newKey = newKey.substr(0, 1).toLowerCase() + newKey.substr(1);
				let dotIndex = newKey.indexOf('.');
				while (dotIndex >= 0 && dotIndex + 1 < newKey.length) {
					newKey = newKey.substr(0, dotIndex + 1) + newKey.substr(dotIndex + 1, 1).toLowerCase() + newKey.substr(dotIndex + 2);
					dotIndex = newKey.indexOf('.', dotIndex + 1);
				}
				if (newKey !== key) {
					if (Object.hasOwnProperty.call(responseData.errors, newKey)) {
						responseData.errors[newKey] = responseData.errors[newKey].concat(responseData.errors[key]);
					} else {
						responseData.errors[newKey] = responseData.errors[key];
					}
					delete responseData.errors[key];
				}
			}
		}
	}
}

export function fetchOk(response) {
	if (response.ok) {
		return response;
	} else {
		throw response;
	}
}

export function fetchOkBadRequest(response) {
	if (response.ok || response.status === 400) {
		return response;
	} else {
		throw response;
	}
}

export function is404(error) {
	return error instanceof Response && error.status === 404;
}

export function redirect404(url, message) {
	return function (response) {
		if (response instanceof Response && response.status === 404) {
			return redirectWithMessage(url, message, MessageType.warning);
		} else {
			return response;
		}
	};
}

export function redirect404Direct(response, url, message) {
	if (response instanceof Response && response.status === 404) {
		return redirectWithMessage(url, message, MessageType.warning);
	} else {
		return handleHttpError(response);
	}
}

export function redirectWithMessage(url, message, messageType = MessageType.warning) {
	const $store = useStore();
	$store.addMessage(message, messageType);
	return $router.replace(url);
}
