import Permission from '@/models/Permission';
import { handleImportError, parseDateTimeProp, parseIntProp } from '../_helpers';

export default {
	path: '/Portal/Operator',
	component: () => import('./operator-imports').then(x => x._OperatorApp).catch(handleImportError),
	meta: { appName: 'operator', requiresAuth: true, requiresCompanyUser: true, requiresOperator: true, permissions: [Permission.companyOperator], blockOffline: false, lockOnCompanyChange: true, },
	children: [
		{
			path: '',
			component: () => import('./operator-imports').then(x => x.DashboardPage).catch(handleImportError),
			meta: { title: 'My service orders', requiresPunchIn: true, isHomePage: true, },
		},
		{
			path: 'LocalChanges',
			component: () => import('./operator-imports').then(x => x.LocalChangesPage).catch(handleImportError),
			meta: { title: 'Unsynced changes', },
		},
		{
			path: 'VehicleReport',
			component: () => import('./operator-imports').then(x => x.VehicleReportPage).catch(handleImportError),
			meta: { title: 'Vehicle report', },
			props: route => ({
				date: parseDateTimeProp(route.query.date),
				vehicleId: parseIntProp(route.query.vehicleId),
			}),
		},
		{
			path: 'Timesheets/Punch',
			component: () => import('./operator-imports').then(x => x.TimesheetsPunchPage).catch(handleImportError),
			props: route => ({ referrer: route.query.referrer, }),
			meta: { title: 'Timeclock', },
		},
		{
			path: 'WasteDisposal',
			component: () => import('./operator-imports').then(x => x.WasteDisposalPage).catch(handleImportError),
			meta: { title: 'Waste disposal', requiresPunchIn: true, },
			props: route => ({
				filter: route.query.filter,
				sort: route.query.sort,
			}),
		},
		{
			path: 'WasteDisposal/Details/:id(-?\\d+)',
			component: () => import('./operator-imports').then(x => x.WasteDisposalDetailsPage).catch(handleImportError),
			meta: { title: 'Waste wisposal details', requiresPunchIn: true, },
			props: route => ({ id: parseIntProp(route.params.id) }),
		},
		{
			path: 'WasteDisposal/Edit/:id(-?\\d+)',
			component: () => import('./operator-imports').then(x => x.WasteDisposalEditPage).catch(handleImportError),
			meta: { title: 'Waste disposal', requiresPunchIn: true, },
			props: route => ({ id: parseIntProp(route.params.id), siteId: parseIntProp(route.query.siteId) }),
		},
		{
			path: 'WorkOrders/Edit/:id(\\d+)',
			component: () => import('./operator-imports').then(x => x.WorkOrderEditPage).catch(handleImportError),
			meta: { title: 'Service order', requiresPunchIn: true, },
			props: route => ({ id: parseIntProp(route.params.id) }),
		},
		{
			path: 'WorkOrders/Invoice/:id(\\d+)',
			component: () => import('./operator-imports').then(x => x.InvoicePage).catch(handleImportError),
			meta: { title: 'Invoice', requiresPunchIn: true, },
			props: route => ({ id: parseIntProp(route.params.id) }),
		},
	]
};
