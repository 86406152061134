<template>
	<div class="account-links">
		<router-link to="/Account/Manage" title="Manage">
			<svg class="icon">
				<use xlink:href="#i-profile"></use>
			</svg>
		</router-link>
		<ul>
			<template v-if="$store.isLoggedIn">
				<li>
					<router-link to="/Account/Manage" title="Manage">Hello {{ $store.user.name }}!</router-link>
				</li>
				<li class="flex-center-y">
					<button type="button" @click="logout">Logout</button>
					<div class="flex-center-x ml-1 tooltip-contain tooltip-left" v-if="$store.user.isImpersonating">
						<router-link to="/Admin" class="flex-center-y tooltip-hover">
							<svg class="icon" style="width:24px; height: 24px;">
								<use xlink:href="#i-stop-impersonation"></use>
							</svg>
							<span>Leave</span>
						</router-link>
						<span class="tooltip">Stop impersonating company</span>
					</div>
				</li>
			</template>
			<li v-else>
				<router-link to="/Account/Login">Login</router-link>
			</li>
		</ul>
	</div>

	<div class="placeholder"></div>
</template>

<script setup>
import api from '@/api';
import { handleHttpError } from '@/helpers/data';
import { getIdb, localChanges, localChangesInUse } from '@/idb';
import MessageType from '@/models/MessageType.js';
import { useStore } from '@/store';
import { inject } from 'vue';
import { useRouter } from 'vue-router';

const $store = useStore();
const $router = useRouter();
const confirmDialog = inject('confirmDialog');

async function logout() {
	const idb = localChangesInUse.value ? await getIdb() : null;
	if (idb && await localChanges.getCount(idb) > 0) {
		if (!await confirmDialog.value.openDialog('You have unsynced local changes that will be lost when you log out.', {
			type: MessageType.warning,
			confirmText: 'Discard changes and logout',
			closeOnConfirm: true,
		})) { return; }
	}
	const response = await api.account.logout();
	if (response.ok) {
		await $store.refresh();
		$router.push('/');
	} else {
		handleHttpError(response);
	}
}
</script>
