import { useStore } from '@/store';
import { register } from 'register-service-worker';

export function registerSW() {
	if (import.meta.env.MODE === 'production') {
		register(`${import.meta.env.BASE_URL}service-worker.js`, {
			ready() {
				console.log(
					'App is being served from cache by a service worker.\n' +
					'For more details, visit https://goo.gl/AFskqB'
				)
			},
			registered() {
				console.log('Service worker has been registered.')
			},
			cached() {
				console.log('Content has been cached for offline use.')
			},
			updatefound() {
				console.log('New content is downloading.')
			},
			updated() {
				console.log('New content is available; please refresh.');
				const $store = useStore();
				$store.serviceWorkerUpdate = true;
			},
			offline() {
				console.log('No internet connection found. App is running in offline mode.')
			},
			error(error) {
				const $store = useStore();
				if ($store.isOnline) {
					console.error('Error during service worker registration:', error)
				}
			}
		})
	}
}
