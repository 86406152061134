<template>
	<span class="is-loading dots" v-if="props.type === 'dots'">
		<span class="dot-1"></span>
		<span class="dot-2"></span>
		<span class="dot-3"></span>
	</span>
</template>

<script setup>
const props = defineProps({
	type: {
		type: String,
		required: true,
	},
});
</script>
