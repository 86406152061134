<template>
	<teleport to="#teleported">
		<transition name="refresh-sw" appear>
			<div v-if="open" class="refresh-sw-prompt">
				<div>App update available!</div>
				<button class="btn-primary large-btn px-2" :disabled="loading" @click="refresh()">
					<span class="button-text" :class="{ hidden: loading }">Update</span>
					<loading-indicator type="dots" v-if="loading" />
				</button>
			</div>
		</transition>
	</teleport>
</template>

<script setup>
import { useStore } from '@/store';
import { computed, ref } from 'vue';

const $store = useStore();
const loading = ref(false);
const open = computed(() => $store.serviceWorkerUpdate);

function refresh() {
	loading.value = true;

	if (navigator.serviceWorker) {
		navigator.serviceWorker.ready.then(worker => {
			if (worker.waiting) {
				worker.waiting.postMessage('skipWaiting')
			}
		});
	}
}

let refreshing = false;
if (navigator.serviceWorker) {
	navigator.serviceWorker.addEventListener('controllerchange', () => {
		if (refreshing) return;
		refreshing = true;
		window.location.reload();
	});
}
</script>
