import { handleImportError, parseBoolProp, parseIntProp } from '../_helpers';

export default [
	{
		path: '/Account',
		component: () => import('./account-imports').then(x => x._AccountApp).catch(handleImportError),
		meta: { blockOffline: true, appName: 'account', },
		children: [
			{
				path: '',
				redirect: '/Account/Manage',
			},
			{
				path: 'ForgotPassword',
				component: () => import('./account-imports').then(x => x.ForgotPasswordPage).catch(handleImportError),
				meta: { title: 'Forgot Password', requiresAnonymous: true, },
			},
			{
				path: 'Login',
				component: () => import('./account-imports').then(x => x.LoginPage).catch(handleImportError),
				props: route => ({ referrer: route.query.referrer, }),
				meta: { title: 'Login', requiresAnonymous: true, isHomePage: true, isLoginPage: true },
			},
			{
				path: 'Manage',
				component: () => import('./account-imports').then(x => x.ManagePage).catch(handleImportError),
				meta: { title: 'Manage Account', requiresAuth: true, },
			},
			{
				path: 'Companies',
				component: () => import('./account-imports').then(x => x.CompaniesPage).catch(handleImportError),
				props: route => ({ select: parseBoolProp(route.query.select), }),
				meta: { title: 'Companies', requiresAuth: true, },
			},
			{
				path: 'ResetPassword',
				component: () => import('./account-imports').then(x => x.ResetPasswordPage).catch(handleImportError),
				props: route => ({ code: route.query.code, }),
				meta: { title: 'Reset Password', },
			},
			{
				path: 'Setup',
				component: () => import('./account-imports').then(x => x.SetupPage).catch(handleImportError),
				props: route => ({ code: route.query.code, userId: parseIntProp(route.query.userId) }),
				meta: { title: 'Setup Account', },
			},
		]
	},
	{
		path: '/Account/ConfirmEmailChange',
		component: () => import('./account-imports').then(x => x.ConfirmEmailChangePage).catch(handleImportError),
		meta: { blockOffline: true, appName: 'account', title: 'Confirm Email Change', },
		props: route => ({
			userId: parseIntProp(route.query.userId),
			email: route.query.email,
			code: route.query.code,
		}),
	},
];
