<template>
	<div class="alert" :class="[messageClosed ? 'animate-out' : 'animate-in', `alert-${message.type}`]">
		<svg class="icon type-icon">
			<use :xlink:href="`#msg-${message.type}`"></use>
		</svg>
		<span class="message">{{ message.message }}</span>
		<button class="close-message" type="button" @click="closeMessage">
			<svg :class="{ 'auto-close': message.autoClose }">
				<circle :style="message.autoClose ? autoCloseStyle : ''" r="10" cx="12" cy="12"></circle>
			</svg>
		</button>
	</div>
</template>

<script>
import Message from '@/models/Message';
import { useStore } from '@/store';
import { computed, ref, onMounted } from 'vue';

export default {
	props: {
		message: {
			type: Object,
			required: true,
		},
	},
	setup(props) {
		const $store = useStore();
		const messageClosed = ref(false);
		const autoCloseStyle = computed(() => ({ animationDuration: Message.AUTO_CLOSE_DELAY + 'ms' }));
		function closeMessage() {
			messageClosed.value = true;
			setTimeout(() => {
				$store.removeMessage(props.message.id);
				// 1s delay for animate out:
			}, 1000);
		}
		onMounted(() => {
			if (props.message.autoClose) {
				setTimeout(() => {
					messageClosed.value = true;
				}, Message.AUTO_CLOSE_DELAY);
			}
		});
		return {
			messageClosed,
			autoCloseStyle,
			closeMessage,
		};
	},
};
</script>
