import { useLogging } from '@/helpers/logging';
import { registerSW } from '@/helpers/registerServiceWorker';
import { setupStore } from '@/helpers/setupStore';
import { useYupExtensions } from '@/helpers/yupExtensions';
import $router from '@/router';
import '@/scss/main.scss';
import { useStore } from '@/store';
import App from '@/views/_App.vue';
import ButtonSubmit from '@/views/_shared/ButtonSubmit.vue';
import HeadingPartial from '@/views/_shared/HeadingPartial.vue';
import InputCheckboxRadio from '@/views/_shared/InputCheckboxRadio.vue';
import LoadingIndicator from '@/views/_shared/LoadingIndicator.vue';
import PageLoading from '@/views/_shared/PageLoading.vue';
import TransitionHeight from '@/views/_shared/TransitionHeight.vue';
import { createPinia } from 'pinia';
import { createApp } from 'vue';
import { broadcast } from './helpers/broadcast';

// register the service worker right away if one is already installed
if (navigator.serviceWorker && navigator.serviceWorker.controller) {
	registerSW();
}

const app = createApp(App);

app.use(createPinia());
export const $store = useStore();
useLogging(app, $store, $router);
useYupExtensions();

app.component('ButtonSubmit', ButtonSubmit);
app.component('HeadingPartial', HeadingPartial);
app.component('InputCheckboxRadio', InputCheckboxRadio);
app.component('LoadingIndicator', LoadingIndicator);
app.component('PageLoading', PageLoading);
app.component('TransitionHeight', TransitionHeight);

(async () => {
	await $store.refreshAuth();
	await $store.refreshSettings();

	if ((!navigator.serviceWorker || !navigator.serviceWorker.controller) && $store.isLoggedIn) {
		registerSW();
	}

	app.use($router);
	app.mount('#app');

	await Promise.all([
		$store.refreshLastTimeClockPunch(),
		$store.refreshCacheIfNeeded(),
	]);
})();

setupStore($store, $router);

// light & dark favicon
const darkMode = window.matchMedia('(prefers-color-scheme: dark)');
const swapFavicon = () => document.head.querySelector('link[rel="shortcut icon"]').setAttribute('href', darkMode.matches ? '/favicons/favicon-light.ico' : '/favicons/favicon.ico');
darkMode.addEventListener('change', swapFavicon);
swapFavicon();

// change mask-icon color in non-production environments
const setMaskIconColor = (color) => document.head.querySelector('link[rel="mask-icon"]').setAttribute('color', color);
const env = $store.environment;
if (env === 'development' || env === 'testing') {
	setMaskIconColor('#111111');
}

// Update online and offline status
function updateOnlineStatus() {
	$store.isOnline = navigator.onLine;
	if (navigator.serviceWorker && $store.isOnline) {
		navigator.serviceWorker.ready.then(worker => {
			worker.active.postMessage('sync-local-changes');
		});
	}
}
updateOnlineStatus();
window.addEventListener('online', updateOnlineStatus);
window.addEventListener('offline', updateOnlineStatus);

// PWA install banner
window.addEventListener('beforeinstallprompt', (e) => {
	e.preventDefault();
	$store.setCustomPwaBanner(true);
	$store.showInstallButton = true;
	$store.setPwaPrompt(e);
});

// Logout all tabs
broadcast.addEventListener('message', event => {
	if (event.data && event.data.action === 'reload') {
		window.location.reload();
	}
});
